import {useForm} from "react-hook-form";
import React, {useState, useRef, useEffect, useCallback} from "react";
import {useNavigate} from 'react-router-dom';
import {sendZipCommandDichotomous} from "./sendZipConfigDichotomous.js"
import './GeneralConfig.css';

import {AwesomeButton, AwesomeButtonProgress} from "react-awesome-button";

import {
    AppBar,
    Box,
    Button,
    Typography,
    Icon,
    IconButton,
    makeStyles,
    createTheme,
    ThemeProvider,
    Toolbar,
    Tooltip,
    FormHelperText
} from "@material-ui/core";

function GeneralConfig(props) {

    const navigate = useNavigate();

    const {setValue, register, handleSubmit, watch, formState: {errors}} = useForm();
    const watchDB = watch("db");
    const oldDB = useRef(watchDB)

    const [geneset1File, setGeneset1File] = useState(null);
    const [geneset2File, setGeneset2File] = useState(null);

    const geneset1Ref = useRef(null);
    const geneset2Ref = useRef(null);

    const [fileErrors, setFileErrors] = useState({geneset1: '', geneset2: ''});

    const maxFileSize = 5 * 1024 * 1024; // 5MB

    const checkGeneset = (e, genesetSetter) => {
        let file = e.target.files[0]

        console.log("Checking Geneset")

        if (!file) return 'No file selected';

        // Check file size
        if (file.size > maxFileSize) {
            return 'File size exceeds 5MB limit.';
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const sampleIdentifiers = new Set();
            const lines = text.split('\n');
            let lineNumber = 0;

            for (let rawLine of lines) {
                lineNumber++;
                const line = rawLine.trim();

                // Skip empty lines or comments
                if (!line || line.startsWith('#') || line.startsWith('//')) continue;

                const idx = line.indexOf('=');
                if (idx === -1) {
                    alert(`Geneset File ERROR \nFormatting error on line ${lineNumber}: missing '='.`);
                    //setSuccessMessage('');
                    return;
                }

                const sampleIdentifier = line.substring(0, idx).trim();
                if (!sampleIdentifier) {
                    alert(`Geneset File ERROR \nFormatting error on line ${lineNumber}: sample identifier is empty.`);
                    //setSuccessMessage('');
                    return;
                }

                if (sampleIdentifiers.has(sampleIdentifier)) {
                    alert(`Geneset File ERROR \nDuplicate sample identifier found on line ${lineNumber}: '${sampleIdentifier}'`);
                    //setSuccessMessage('');
                    return;
                }

                // We want to check that the sample/patient names do not duplicate between both samples
                sampleIdentifiers.add(sampleIdentifier);

                //const geneList = line.substring(idx + 1).split(',');
                //const validGenes = geneList.map(g => g.trim()).filter(g => g !== '');

                //console.log(validGenes)
            }

            console.log(sampleIdentifiers)
            genesetSetter(sampleIdentifiers);

            // Success!
            console.log('File validation successful! Ready to upload.');
            //setErrorMessage('');
            // TODO: proceed to upload or whatever else you need to do
        };

        reader.readAsText(file);

        return '';
    };

    const handleGeneset1Change = (e, genesetRef) => {
        // const file = e.target.files[0];
        // const error = validateFile(file, geneset2File);
        // setFileErrors(prev => ({...prev, geneset1: error}));
        checkGeneset(e, setGeneset1File)
    };

    const handleGeneset2Change = (e, genesetRef) => {
        // const file = e.target.files[0];
        // const error = validateFile(file, geneset1File);
        // setFileErrors(prev => ({...prev, geneset2: error}));
        checkGeneset(e, setGeneset2File)
    };

    const onSubmit = (data) => {
        console.log("GOT HERE AGAIN")
        console.log(data)
        let extraInfo = {}
        extraInfo["usingOldGeneset"] = props.useOldGenesets;
        extraInfo["previousID"] = props.previousID;
        extraInfo["previousConfig"] = props.previousConfig;

        console.log(geneset1File)
        console.log(geneset2File)
        //Check for overlapping patient info
        function findDuplicates(set1, set2) {
            return [...set1].filter(item => set2.has(item));
        }

        let dups = findDuplicates(geneset1File, geneset2File)
        if (dups.length !== 0) {
            alert("GENESET File Error\nFound the following duplicate patient IDs, patient ID's should be unique across both genesets: " + dups)
            return;
        }

        //IMPORTANT:
        props.setAnalysisType("newProjectDichotomous")

        sendZipCommandDichotomous(props, data, extraInfo).then((ID) => {
            props.setID(ID);
            props.setConfigData(data)
            //setClickedNewProject(true);
            navigate('/project/' + ID, {replace: true})
            console.log(props)
            props.setProjectName(data["title"])
        })
    };

    useEffect(() => {
        console.log(watchDB)
        console.log(oldDB.current)

        changeMaxPathCost(watchDB)
        oldDB.current = watchDB
    }, [watchDB]);

    const useStyles = makeStyles({
        submitButton: {
            alignItems: 'center',
            flexDirection: 'row',
            width: '20vw',
            padding: 5,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'lightblue',
            borderRadius: 5,
            color: 'black',
        }
    });

    const classes = useStyles();


    const [show, setShow] = useState(false);
    const [fileNumber, setFileNumber] = useState(1);

    function changeMaxPathCost(currDB) {
        console.log(currDB)
        if (currDB === "string") {
            setValue("maxpath", 1000)
        } else if (currDB === "intact") {
            console.log("setting to intact")
            setValue("maxpath", 10000)
        } else if (currDB === "mint") {
            console.log("setting to mint")
            setValue("maxpath", 10000)
        }
    }

    return (
        <div>
            <Box fontWeight={700} fontSize={30} fontFamily={'Oswald'} letterSpacing={2}>
                <b>NEW PROJECT CONFIGURATION</b>
            </Box>
            <Box fontWeight={300} fontSize={20} fontFamily={'Oswald'} letterSpacing={2}
                 style={{display: 'flex', justifyContent: 'space-around', margin: '10px'}}>
                DICHOTOMOUS GENESET ANALYSIS
                {/*<Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em", border: "1px solid white"}}*/}
                {/*        onClick={() => {*/}
                {/*            setFileNumber(1)*/}
                {/*        }}>SINGLE SAMPLE GENESET ANALYSIS</Button>*/}
                {/*<Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em", border: "1px solid white"}}*/}
                {/*        onClick={() => {*/}
                {/*            setFileNumber(2)*/}
                {/*        }}>MULTI-SAMPLE GENESET ANALYSIS</Button>*/}
            </Box>
            <div className="GeneralConfig">
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* register your input into the hook by invoking the "register" function */}

                        <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                            <u>PROJECT TITLE</u><span>: </span>
                            <input defaultValue={"my-first-project"} {...register("title", {required: false})} />
                        </Box>
                        <br></br>
                        <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                            <u>GENESET 1</u><span>: </span>
                            {props.useOldGenesets ?
                                <span><span style={{"fontWeight": "bold"}}>{"Using Previous Geneset 1"}</span>
                  <input type="file" disabled={!props.useOldGenesets} ref={geneset1Ref}
                         style={{"display": "none"}} {...register("geneset1", {
                      required: !props.useOldGenesets,
                      onChange: (e) => {
                          handleGeneset1Change(e, geneset1Ref);
                      }
                  })} />
                  </span>
                                :
                                <input type="file" disabled={props.useOldGenesets} ref={geneset1Ref}
                                       style={{"display": "inline"}} {...register("geneset1", {
                                    required: true,
                                    onChange: (e) => {
                                        handleGeneset1Change(e, geneset1Ref);
                                    }
                                })} />}
                        </Box>
                        <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                            <u>GENESET 2</u><span>: </span>
                            {props.useOldGenesets ?
                                <span><span style={{"fontWeight": "bold"}}>{"Using Previous Geneset 2"}</span>
                  <input type="file" disabled={!props.useOldGenesets} ref={geneset2Ref}
                         style={{"display": "none"}} {...register("geneset2", {
                      required: !props.useOldGenesets,
                      onChange: (e) => {
                          handleGeneset2Change(e, geneset2Ref);
                      }
                  })} />
                  </span>
                                :
                                <input type="file" disabled={props.useOldGenesets} ref={geneset2Ref}
                                       style={{"display": "inline"}} {...register("geneset2", {
                                    required: false,
                                    onChange: (e) => {
                                        handleGeneset2Change(e, geneset2Ref);
                                    }
                                })} />}
                        </Box>


                        {errors.geneset1 &&
                            <Box fontWeight={300} fontSize={12} fontFamily={'Open Sans'} color={"red"}>GeneSet 1 Needs
                                to Have a File <br></br></Box>}
                        {errors.geneset2 &&
                            <Box fontWeight={300} fontSize={12} fontFamily={'Open Sans'} color={"red"}>GeneSet 2 Needs
                                to Have a File <br></br></Box>}


                        <br></br>
                        <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                            <u>PPI DATABASE</u><span>: </span>
                            <select type="file" {...register("db", {required: true})}>
                                <option value="string">STRING</option>
                                <option value="intact">INTACT</option>
                                <option value="mint">MINT</option>
                            </select>
                        </Box>

                        <br></br>
                        <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em"}}
                                onClick={() => setShow(prev => !prev)}>↓ Advanced Settings ↓</Button>
                        <div style={{"display": (show ? "block" : "none")}}>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>MAX PATH LENGTH</u><span>: </span>
                                <input
                                    defaultValue={3} {...register("pathLength", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 3})} />
                            </Box>

                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>MIN INTERACTOME CONFIDENCE</u><span>: </span>
                                <input
                                    defaultValue={0} {...register("minpath", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) >= 0) ? parseInt(v) : 0})} />
                            </Box>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>MAX PATH COST</u><span>: </span>
                                <input
                                    defaultValue={1000} {...register("maxpath", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 200})} />
                            </Box>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>CLUSTER THRESHOLD</u><span>: </span>
                                <input
                                    defaultValue={0.33} {...register("threshold", {setValueAs: v => (!isNaN(parseFloat(v))) ? parseFloat(v) : 0.33})} />
                            </Box>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>MAX VERTICES TO RENDER</u><span>: </span>
                                <input
                                    defaultValue={50} {...register("vertices", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 50})} />
                            </Box>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>REPULSION CONSTANT</u><span>: </span>
                                <input
                                    defaultValue={1.2} {...register("repulsion", {setValueAs: v => (!isNaN(parseFloat(v))) ? parseFloat(v) : 1.2})} />
                            </Box>

                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>GROUP 1 ALIAS</u><span>: </span>
                                <input defaultValue={"group1"} {...register("group1alias", {
                                    required: true,
                                    setValueAs: v => (v)
                                })} />
                            </Box>

                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>GROUP 2 ALIAS</u><span>: </span>
                                <input defaultValue={"group2"} {...register("group2alias", {
                                    required: true,
                                    setValueAs: v => (v)
                                })} />
                            </Box>
                        </div>


                        <br></br>

                        <AwesomeButtonProgress type={"primary"} className={"CreateProjectButton"}
                                               element={({children, style, className}) =>
                                                   <button style={style} className={className}
                                                           type={'submit'}>{children}</button>}>
                            Create Project
                        </AwesomeButtonProgress>
                    </form>
                </div>


            </div>
        </div>
    );
}

export default GeneralConfig;

