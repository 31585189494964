import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import Layout from "../Layouts/Layout";
import LayoutSingleSample from "../Layouts/LayoutSingleSample";

import { useHistory, useParams } from 'react-router-dom'
import {Box, createTheme, makeStyles, Modal, ThemeProvider} from "@material-ui/core";
import Help from "../MiscComponents/help/Help";
import NavBar from "../MiscComponents/navbar/NavBar";
import filePath from "../backendconnect/FilePath";
import getStatus from "../backendconnect/GetStatus"
import { Navigation } from "../MiscComponents/navbar/AppNav";

// import 'react-statusbar/dist/statusbar.css';
// import * as Statusbar from 'react-statusbar';

import ProgressBar from "../MiscComponents/primitive/Progress";
import IDLoad from "../backendconnect/IDLoad";

function DendrogramLoad(props) {

    const { id } = useParams()


    const [imageLink, setImageLink] = useState("");
    const [content, setContent] = useState(<div>Loading...</div>);
    //EDIT ADDED 9/1
    const [contentStr, setContentStr] = useState("Loading...");

    const [idFound, setIDFound] = useState("");
    const webConfigObjRef = useRef({"title":""});


    useEffect(() => {
        console.log("props from dendrogram load")
        console.log(props)
        IDLoad(props.address, id).then((res) => {

            if (res === undefined || res === 500) {
                setContent(<div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div style={{"fontSize": "30px"}}>Your Data Could Not Be Found</div>
                    <br></br>
                    <div> This means you either entered an incorrect or expired Project ID</div>
                </div>)
                return
            }

            let webConfigData = res["webConfig"].split(/\r?\n/);
            let webConfigObj = {}
            for (let i in webConfigData) {
                let key = webConfigData[i].split(": ")[0]
                let val = webConfigData[i].split(": ")[1]
                if (key.length === 0) {
                    continue;
                }
                webConfigObj[key] = val
            }
            webConfigObj["expireDate"] = res["expireDate"];

            console.log("webConfigData")
            console.log("webConfigData")
            webConfigObjRef.current = webConfigObj;

            let getDeno = setInterval(() => {
                getStatus(props.address, id, setContent, "genesetmap").then((stat) => {
                    if (stat.countStatus >= stat.totalCount && stat.statusLayer == 1) {

                        //EDIT: ADDED IF!
                        // if (props.analysisType === "newProjectSample") {
                        //     clearInterval(getDeno) // What does this do...?
                        //     setContent(<div>
                        //         <LayoutSingleSample address={props.address} id={id} idString={props.idString}
                        //                 jsonLink={"single_sample"} projectName={webConfigObj["title"]}
                        //                 configData={webConfigObj} analysisType={props.analysisType}/>
                        //     </div>)
                        // } else {
                            filePath(props.address, id, "d3json").then((foundLink) => {
                                console.log("Foundlink is: " + foundLink)
                                setImageLink(foundLink)
                                //EDIT ADDED 9/1
                                if (foundLink === "ERROR: Graph Empty") {
                                    console.log("FOUND THERE WAS AN EMPTY GRAPH")
                                    setContent(<div><br></br><br></br><br></br><br></br><br></br><br></br><br></br><h4>There were not enough connections to make a dendrogram, please try with another database or an increased path length.</h4><br></br></div>)
                                    setContentStr("Not Loading")
                                    clearInterval(getDeno)
                                }

                    //

                                //Commented out following to try and get simple sample working...
                                if (foundLink.includes("runningSSA")) {
                                    console.log("In front end. d3json does not exist.")
                                    clearInterval(getDeno)
                                    setContent(<div>
                                        <LayoutSingleSample address={props.address} id={id} idString={props.idString}
                                                jsonLink={"single_sample"} projectName={webConfigObj["title"]}
                                                configData={webConfigObj} ssID={foundLink.split("runningSSA ")[1]} analysisType={props.analysisType}/>
                                    </div>)
                                    setContentStr("Not Loading")
                                //    Changed following from !== to !includes
                                } else if (!foundLink.includes("ERROR: FILE NOT FOUND WITH KEYWORD d3json") && foundLink !== "" && foundLink !== undefined && foundLink !== "ERROR: Graph Empty") {
                                    clearInterval(getDeno)

                                    setContent(<div>
                                        <Layout address={props.address} id={id} idString={props.idString}
                                                jsonLink={foundLink} projectName={webConfigObj["title"]}
                                                configData={webConfigObj}/>
                                    </div>)
                                    setContentStr("Not Loading")
                                }
                            })
                        // }

                    }
                })
            }, 1000)
        })

    }, []);

    /*
    let output;
    //<Dendrogram imageurl={imageLink}/>
    //<a href={imageLink} download><AwesomeButton>Download Dendrogram</AwesomeButton></a>
    console.log("IMPORTANT PART")
    console.log(props.imageLink)
    console.log("IMPORTANT PART2")
    if (props.imageLink !== "FILEPATH ERROR: FILE NOT FOUND WITH KEYWORD d3json" && props.imageLink !== "" && props.imageLink !== undefined) {
        console.log(props.imageLink)
        output =
            <div>
            <Layout address={props.address} id={props.id} idString={props.idString} jsonLink={props.imageLink}/>
            </div>
    } else {
        output = <p>{"image not loaded"}</p>;
    }
     */

    const [showHelp, setShowHelp] = useState(false);
    const [projectInit,setProjectInit] = useState(true);

    const useStyles = makeStyles({
        clusterbutton: {
            margin: 100,
        }
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: "#EEF0F2"
            },
            secondary: {
                main: "#EEF0F2",
            },
        },
        typography: {
            fontFamily: [
                'Oswald',
                'Open Sans'
            ].join(','),
        },
    })

    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState(false);
    const handleOpen = (content) => {
        console.log("modal open")
        setModalContent(content);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 750,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        outline: 0,
        textAlign: "center"
    };

    //EDIT ADDED 9/1
    let loadingMargin = "20px"
    let loadingText = <div>
        <br></br>
        <div><b>If your data has many patients or a large max path size the query can take a while...</b></div>
        <div><b>Feel free to come back to <a
            href={"https://" + document.location.host + "/project/" + id}>{"https://" + document.location.host + "/project/" + id}</a> To
            view your project when it is loaded!</b></div>
    </div>

    if (contentStr !== "Loading..." || contentStr === "Not Loading") {

        loadingText = <div></div>

        loadingMargin = "0px"
    }
    //



    return (
        <div style={{textAlign: "center"}}>
            <ThemeProvider theme={theme}>
                <Navigation setShowHelp={setShowHelp} id={id} address={props.address}
                            projectName={webConfigObjRef?.current["title"]} configData={webConfigObjRef?.current}
                            modalOpen={handleOpen}/>
                {/* <br></br>
                <br></br>
                <br></br> */}
                {/* <NavBar setShowHelp={setShowHelp} id={id} address={props.address} projectName={webConfigObjRef?.current["title"]} configData={webConfigObjRef?.current} modalOpen={handleOpen}/> */}
                <Help show={showHelp} setShow={setShowHelp} theme={theme}/>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description">

                    <Box sx={modalStyle} alignItems={"center"}>
                        {modalContent}
                    </Box>

                </Modal>

                <div style={{"marginTop": loadingMargin}}>


                {content}

                {/*EDIT ADDED and COMMENTED 9/1 */}
                {loadingText}

                </div>
                {/*<br></br>*/}
                {/*<div><b>If your data has many patients or a large max path size the query can take a while...</b></div>*/}
                {/*<div><b>Feel free to come back to <a href={"https://" + document.location.host + "/project/" + id}>{"https://" + document.location.host + "/project/" + id}</a> To view your project when it is loaded!</b></div>*/}

            </ThemeProvider>
        </div>
    );
}

export default DendrogramLoad;
